/** @define dark-belt; weak */
.dark-belt {
  color: #ffffff;
}
@media only screen and (min-width: 700px) {
  .dark-belt {
    padding: 0 15px 0 0;
    background: #332f2a;
    background: linear-gradient(90deg, #08090c 0%, #08090c 50%, #14161d 50%, #14161d 100%);
  }
}
@media only screen and (max-width: 599px) {
  .dark-belt--order {
    background: url("../../../images/banner-order/order_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .dark-belt--order {
    background: url("../../../images/banner-order/order_bcg_600.webp") no-repeat top right;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 599px) {
  .no-webp .dark-belt--order {
    background: url("../../../images/banner-order/order_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .no-webp .dark-belt--order {
    background: url("../../../images/banner-order/order_bcg_600.jpg") no-repeat top right;
  }
}
@media only screen and (max-width: 599px) {
  .dark-belt--service {
    background: url("../../../images/banner-servis/servis_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .dark-belt--service {
    background: url("../../../images/banner-servis/servis_bcg_600.webp") no-repeat top right;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 599px) {
  .no-webp .dark-belt--service {
    background: url("../../../images/banner-servis/servis_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 600px) and (max-width: 699px) {
  .no-webp .dark-belt--service {
    background: url("../../../images/banner-servis/servis_bcg_600.jpg") no-repeat top right;
  }
}
.dark-belt__left {
  padding: 100px 15px 50px 15px;
  text-align: right;
}
@media only screen and (min-width: 700px) {
  .dark-belt__left {
    width: 40%;
    padding: 160px 30px 0 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .dark-belt__left .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  .dark-belt__left .title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .dark-belt--order .dark-belt__left {
    background: url("../../../images/banner-order/order_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .dark-belt--order .dark-belt__left {
    padding: 230px 30px 0 30px;
    background: url("../../../images/banner-order/order_bcg_600.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .dark-belt--order .dark-belt__left {
    padding: 330px 80px 0 30px;
    background: url("../../../images/banner-order/order_bcg_850.webp") no-repeat top right;
  }
}
.no-webp .dark-belt--order {
  /* postcss-bem-linter: ignore */
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .no-webp .dark-belt--order .dark-belt__left {
    background: url("../../../images/banner-order/order_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .no-webp .dark-belt--order .dark-belt__left {
    background: url("../../../images/banner-order/order_bcg_600.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .no-webp .dark-belt--order .dark-belt__left {
    background: url("../../../images/banner-order/order_bcg_850.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .dark-belt--service .dark-belt__left {
    background: url("../../../images/banner-servis/servis_bcg_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .dark-belt--service .dark-belt__left {
    padding: 230px 30px 0 30px;
    background: url("../../../images/banner-servis/servis_bcg_600.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .dark-belt--service .dark-belt__left {
    padding: 330px 80px 0 30px;
    background: url("../../../images/banner-servis/servis_bcg_850.webp") no-repeat top right;
  }
}
.dark-belt--service .dark-belt__right li {
  color: #ffffff;
}
.no-webp .dark-belt--service {
  /* postcss-bem-linter: ignore */
}
@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .no-webp .dark-belt--service .dark-belt__left {
    background: url("../../../images/banner-servis/servis_bcg_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .no-webp .dark-belt--service .dark-belt__left {
    background: url("../../../images/banner-servis/servis_bcg_600.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1500px) {
  .no-webp .dark-belt--service .dark-belt__left {
    background: url("../../../images/banner-servis/servis_bcg_850.jpg") no-repeat top right;
  }
}
.dark-belt__right {
  width: 100%;
  padding: 30px 15px 80px 15px;
  background: #14161d;
}
@media only screen and (min-width: 700px) {
  .dark-belt__right {
    width: 60%;
    padding-left: 30px;
    border-left: 1px solid #2b3042;
  }
}
@media only screen and (min-width: 1500px) {
  .dark-belt__right {
    padding-right: 80px;
    padding-left: 80px;
  }
}
/*# sourceMappingURL=css/dark-belt.css.map */